import loadable from '@loadable/component';
import React, { memo } from 'react';
import type { IHomePageUrlGenerator } from '../../../../framework/gis/common/hooks/IHomePageUrlGenerator';
import EntityTypeEnum from '../../../constants/enums/EntityTypeEnum';
import styles from './HomePageContentItems.module.scss';
import HomePageContentItemsErrorMessagePod from './HomePageContentItemsErrorMessagePod';

const HomePageContentItemsAdverts = loadable(() => import(/* webpackChunkName: "page-home-adverts" */'./HomePageContentItemsAdverts'));
const HomePageContentItemsShelters = loadable(() => import(/* webpackChunkName: "page-home-shelters" */'./HomePageContentItemsShelters'));

interface HomePageContentProps {
  isDetail: boolean | undefined;
  activeTab: EntityTypeEnum | undefined;
  urlGenerator: IHomePageUrlGenerator;
}

// let renderCounter = 0;

const HomePageContentItems: React.FC<HomePageContentProps> = ({ isDetail, activeTab, urlGenerator }: HomePageContentProps) => {

  // console.debug(`Render HomePageContentItems ${++renderCounter}`);

  return (
    <article
      id={styles['app-article']}
      className={activeTab && activeTab !== EntityTypeEnum.Advert ? `app-${activeTab}` : undefined}
    >
      <HomePageContentItemsErrorMessagePod />
      {(activeTab === EntityTypeEnum.Advert || activeTab === undefined) && (
        <HomePageContentItemsAdverts
          isDetail={isDetail}
          urlGenerator={urlGenerator}
        />
      )}
      {activeTab === EntityTypeEnum.Shelter && (
        <HomePageContentItemsShelters
          isDetail={isDetail}
          urlGenerator={urlGenerator}
        />
      )}
    </article>
  );
};

export default memo(HomePageContentItems);
