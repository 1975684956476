import loadable from '@loadable/component';
import React from 'react';
import { useSelector } from 'react-redux';
import type { IUserAwareState } from '../../../../framework/base/common/store/modules/app/models/IUserAwareState';
import type { IHomePageUrlGenerator } from '../../../../framework/gis/common/hooks/IHomePageUrlGenerator';
import type EntityTypeEnum from '../../../constants/enums/EntityTypeEnum';
import styles from './HomePageContent.module.scss';
import HomePageContentItems from './HomePageContentItems';
import HomePageContentNavigationTabs from './HomePageContentNavigationTabs';

const UserWelcomeMessageModal = loadable(() => import(/* webpackChunkName: "page-home-user-welcome" */'../../modals/UserWelcomeMessageModal'));

// let renderCounter = 0;

interface HomePageContentProps {
  isDetail: boolean | undefined;
  urlGenerator: IHomePageUrlGenerator;
  detailModeEntityType: EntityTypeEnum | undefined;
}

const HomePageContent: React.FC<HomePageContentProps> = ({ isDetail, urlGenerator, detailModeEntityType }: HomePageContentProps) => {

  // console.debug(`Render HomePageContent ${++renderCounter}`);

  const showWelcomeMessage = useSelector((state: IUserAwareState) => state.user?.showWelcomeMessage);

  const activeTab = urlGenerator.query.tab ?? detailModeEntityType;

  return (
    <div className={styles['app-div']}>
      <HomePageContentItems
        isDetail={isDetail}
        activeTab={activeTab}
        urlGenerator={urlGenerator}
      />
      <HomePageContentNavigationTabs
        urlGenerator={urlGenerator}
        activeTab={activeTab}
        isFetching={false}
      />
      {showWelcomeMessage && detailModeEntityType === undefined && (
        <UserWelcomeMessageModal />
      )}
    </div>
  );
};

export default HomePageContent;
