import React, { useEffect, useState, type PropsWithChildren } from 'react';
import { Link, type To } from 'react-router-dom';

interface SeoLinkProps {
  seoTo: To;
  uiTo: To;
  title: string | undefined;
  className?: string | undefined;
}

const SeoLink: React.FC<PropsWithChildren<SeoLinkProps>> = (props: PropsWithChildren<SeoLinkProps>) => {

  const [to, setTo] = useState<To>(props.seoTo);

  useEffect(() => {
    if (props.uiTo) {
      setTo(props.uiTo);
    }
  }, [props.uiTo]);

  return (
    <Link
      to={to}
      title={props.title}
      className={props.className}
    >
      {props.children}
    </Link>
  );
};

export default SeoLink;
