import loadable from '@loadable/component';
import React from 'react';
import { useSelector } from 'react-redux';
import type { ICommonAwareState } from '../../../../framework/base/common/store/modules/app/models/ICommonAwareState';
import type EntityTypeEnum from '../../../constants/enums/EntityTypeEnum';
import useHomePageUrlGenerator from '../../../hooks/useHomePageUrlGenerator';
import type { FriendlyUrlRoutePredefinedQuery } from '../../layout/routers/AppRouterFriendlyUrlRepository';
import HomePageContent from './HomePageContent';

const HomePageDetailMode = loadable(() => import(/* webpackChunkName: "page-home-detail-mode" */'./HomePageDetailMode'));
const ErrorNotFoundPage = loadable(() => import(/* webpackChunkName: "page-error-not-found" */'../../../../framework/base/common/components/pages/common/ErrorNotFoundPage'));

interface HomePageProps {
  predefinedQuery: FriendlyUrlRoutePredefinedQuery | undefined;
  detailModeEntityType?: EntityTypeEnum | undefined;
  detailModeEntityId?: string | undefined;
  detailModeEntitySlug?: string | undefined;
}

// let renderCounter = 0;

const HomePage: React.FC<HomePageProps> = ({
  predefinedQuery,
  detailModeEntityType,
  detailModeEntityId,
  detailModeEntitySlug,
}: HomePageProps) => {

  const showPageNotFound404 = useSelector((state: ICommonAwareState) => state.common.showPageNotFound404);

  const urlGenerator = useHomePageUrlGenerator(predefinedQuery, true, detailModeEntityType);

  // console.debug(`Render HomePage ${++renderCounter}`, predefinedQuery, urlGenerator);

  return (
    <>
      {showPageNotFound404 && <ErrorNotFoundPage />}
      {!showPageNotFound404 && (
        <>
          {detailModeEntityType !== undefined && (
            <HomePageDetailMode
              urlGenerator={urlGenerator}
              detailModeEntityType={detailModeEntityType}
              detailModeEntityId={detailModeEntityId}
              detailModeEntitySlug={detailModeEntitySlug}
            />
          )}
          <HomePageContent
            isDetail={detailModeEntityType !== undefined}
            urlGenerator={urlGenerator}
            detailModeEntityType={detailModeEntityType}
          />
        </>
      )}
    </>
  );
};

export default HomePage;
