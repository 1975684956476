import loadable from '@loadable/component';
import React from 'react';
import { useSelector } from 'react-redux';
import type { INotificationsAwareState } from '../../../../framework/base/common/store/modules/app/models/INotificationsAwareState';

const ErrorMessagePodAlert = loadable(() => import('./HomePageContentItemsErrorMessagePodAlert'));

// let renderCounter = 0;

const HomePageContentItemsErrorMessagePod: React.FC = () => {

  // console.debug(`Render HomePageContentItemsErrorMessagePod ${++renderCounter}`);

  const errorMessage = useSelector((state: INotificationsAwareState) => state.notifications.errorMessage);

  return errorMessage ?
    (
      <ErrorMessagePodAlert message={errorMessage} />
    )
    : null;
};

export default HomePageContentItemsErrorMessagePod;
